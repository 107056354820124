import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ContactModal from '../ContactModal/ContactModal';
import $ from 'jquery';
import './FurnitureItem.css';


const FurnitureItem = ({ product }) => {

  
    


    const [showContactModal, setShowContactModal] = useState(false);
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const [isTransparent, setIsTransparent] = useState(false);
    const [currentColorIndex, setCurrentColorIndex] = useState(0);
    const [metalColors, setMetalColors] = useState([]);
    const [woodTypes, setWoodTypes] = useState([]); // Array for wood types
    const [currentWoodIndex, setCurrentWoodIndex] = useState(0); // Index for wood type
    const navigate = useNavigate(); // Initialize useNavigate


    useEffect(() => {

        // Fetch wood types from PHP file
        $.ajax({
            url: `/php/getWoodTypes.php`,
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                const woodTypeNames = data.map(item => item.name); // Extract only the names
                setWoodTypes(woodTypeNames); // Store only names in state
                
            },
            error: function (xhr, status, error) {
                console.error('AJAX Error:', status, error);
            }
        });   
        
        // Fetch metal colors from PHP file
        $.ajax({
            url: `/php/getMetalColors.php`,
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                const colorArray = data.map(item => ({
                    name: item.name,
                    value: item.value
                }));
                setMetalColors(colorArray);
            },
            error: function (xhr, status, error) {
                console.error('AJAX Error:', status, error);
            }
        });

        // Set SVGs to become transparent 1 second after mounting
        const timer = setTimeout(() => {
            setIsTransparent(true);
        }, 1000);

        return () => clearTimeout(timer); // Clear timeout on unmount
    }, []);
    
    const openContactModal = () => {
        setShowContactModal(true);
    }

    const closeContactModal = () => {
        $('#contact-modal').removeClass('visible');

        setTimeout(() => {
            setShowContactModal(false);
        }, 600);
        document.body.classList.remove('modal-open');
    }

    const nextImage = () => {
        if (currentPhotoIndex < product.numOfPhotos - 1) {
            setCurrentPhotoIndex(currentPhotoIndex + 1);
        } else {
            setCurrentPhotoIndex(0);
        }
    };

    const previousImage = () => {
        if (currentPhotoIndex > 0) {
            setCurrentPhotoIndex(currentPhotoIndex - 1);
        } else {
            setCurrentPhotoIndex(product.numOfPhotos - 1);
        }
    }

    const handleNextColor = () => {
        setCurrentColorIndex((currentColorIndex + 1) % metalColors.length);
    };

    const handlePreviousColor = () => {
        setCurrentColorIndex((currentColorIndex - 1 + metalColors.length) % metalColors.length);
    };

    const handleNextWood = () => {
        setCurrentWoodIndex((prevIndex) => (prevIndex + 1) % woodTypes.length);
    };

    const handlePreviousWood = () => {
        setCurrentWoodIndex((prevIndex) => (prevIndex - 1 + woodTypes.length) % woodTypes.length);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeContactModal();
                navigate('/produkty'); // Optionally navigate to /produkty
            }
        };



        // Add the keydown event listener
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup: remove the event listener on unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [navigate]); // Add dependencies

    const currentWoodType = woodTypes[currentWoodIndex];

    return (
        <div className="flex-container-product">
                <div id="product-info">
                    <div id='closeButton'>
                        <Link to='/produkty'>
                        <svg id="closeButtonProductSVG" data-name="button on " xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 33.65 33.962">
                            <g id="Rectangle_38" data-name="Rectangle 38" fill="#eaeaeb" stroke="#141314" stroke-width="1">
                                <rect x="0.5" y="0.5" width="32.65" height="32.962" rx="16.325" fill="#eaeaeb"/>
                            </g>
                            <line id="Line_32" data-name="Line 32" x2="16.918" y2="17.077" transform="translate(8.366 8.442)" fill="none" stroke="#141314" stroke-width="1"/>
                            <line id="Line_33" data-name="Line 33" x1="16.918" y2="17.077" transform="translate(8.366 8.442)" fill="none" stroke="#141314" stroke-width="1"/>
                        </svg>


                        </Link>
                    </div>
                    <div id='info-row-1'>
                        <h1 id="product-name">{product.name}</h1>
                        {product.description && <p id="desc" style={{ whiteSpace: 'pre-line' }}>{product.description}</p>}
                    </div>
                    <div id='info-row-2'>
                        <div id='info-col-1'>
                            
                            <p id="price">{product.price}</p>
                            
                            
                            <span id='materialy-span'><b>materiały</b></span>

                            <div id='materialy-container'>
                                <div id='drewno-container'>
                                    <span id='drewno-value'>{currentWoodType}</span>
                                    
                                    <div className='arrows-container' id='drewno'>
                                        <svg id="arrow-left-thin" onClick={handlePreviousWood} data-name="Layer 2" width='20' height='20' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.54 24.11">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <g id="Path_58" data-name="Path 58">
                                                <path fill='#a9b1aa' d="M12.54,24.11c-.25,0-.5-.09-.69-.28L.31,12.78c-.2-.19-.31-.45-.31-.72s.11-.53.31-.72L11.84.28c.4-.38,1.03-.37,1.41.03s.37,1.03-.03,1.41L2.45,12.06l10.78,10.33c.4.38.41,1.02.03,1.41-.2.21-.46.31-.72.31Z"/>
                                                </g>
                                            </g>
                                        </svg>

                                        <div id='drewno-kolor'>
                                            <img 
                                                src={`${process.env.PUBLIC_URL}/images/drewno-rodzaje/${currentWoodType}.jpg`} 
                                                alt={currentWoodType} 
                                            />
                                        </div>
                                        
                                        <svg id="arrow-right-thin" onClick={handleNextWood}  data-name="Layer 2" width='20' height='20' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.54 24.11">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <g id="Path_58" data-name="Path 58">
                                                <path fill='#a9b1aa' d="M1,24.11c.25,0,.5-.09.69-.28l11.54-11.06c.2-.19.31-.45.31-.72s-.11-.53-.31-.72L1.69.28C1.29-.1.66-.09.28.31s-.37,1.03.03,1.41l10.78,10.33L.31,22.39c-.4.38-.41,1.02-.03,1.41.2.21.46.31.72.31Z"/>
                                                </g>
                                            </g>
                                        </svg>

                                    </div>
                                </div>

                                <div id='stal-container'>
                                    <span id='color-value'>{metalColors[currentColorIndex]?.name || 'Loading...'}</span>
                                    <div className='arrows-container' id='metal'>
                                        <svg id="arrow-left-thin" onClick={handlePreviousColor} data-name="Layer 2" width='20' height='20' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.54 24.11">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <g id="Path_58" data-name="Path 58">
                                                <path fill='#a9b1aa' d="M12.54,24.11c-.25,0-.5-.09-.69-.28L.31,12.78c-.2-.19-.31-.45-.31-.72s.11-.53.31-.72L11.84.28c.4-.38,1.03-.37,1.41.03s.37,1.03-.03,1.41L2.45,12.06l10.78,10.33c.4.38.41,1.02.03,1.41-.2.21-.46.31-.72.31Z"/>
                                                </g>
                                            </g>
                                        </svg>

                                        <div id='metal-kolor' style={{ backgroundColor: metalColors[currentColorIndex]?.value || '#asdasd'}}></div>

                                        <svg id="arrow-right-thin" onClick={handleNextColor} data-name="Layer 2" width='20' height='20' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.54 24.11">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <g id="Path_58" data-name="Path 58">
                                                <path fill='#a9b1aa' d="M1,24.11c.25,0,.5-.09.69-.28l11.54-11.06c.2-.19.31-.45.31-.72s-.11-.53-.31-.72L1.69.28C1.29-.1.66-.09.28.31s-.37,1.03.03,1.41l10.78,10.33L.31,22.39c-.4.38-.41,1.02-.03,1.41.2.21.46.31.72.31Z"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                                
                            <div className='arrows-container'>
                                
                            </div>

                            <div id='dimensions'>
                                {product.height && <p id='height'>Wysokość <span>{product.height}</span></p>}
                                {product.width && <p id='width'>Szerokość <span>{product.width}</span></p>}
                                {product.depth && <p id='depth'>Głębokość <span>{product.depth}</span></p>}
                            </div> 

                            <div id='button-container-1'>
                                <button onClick={openContactModal}>ZAPYTAJ</button>
                            </div>
                        </div>
                        
                        <div id='info-col-2'>
                            <span style={{fontWeight: 'bold', fontSize: '14px'}}>materiały</span>
                            <div id='materialy-flex'>
                                <div id='materialy-flex-drewno'>
                                    <span id='drewno-value'>{currentWoodType}</span>
                                    <div className='arrows-container' id='drewno'>
                                        <svg id="arrow-left-thin" onClick={handlePreviousWood} data-name="Layer 2" width='20' height='20' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.54 24.11">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <g id="Path_58" data-name="Path 58">
                                                <path fill='#a9b1aa' d="M12.54,24.11c-.25,0-.5-.09-.69-.28L.31,12.78c-.2-.19-.31-.45-.31-.72s.11-.53.31-.72L11.84.28c.4-.38,1.03-.37,1.41.03s.37,1.03-.03,1.41L2.45,12.06l10.78,10.33c.4.38.41,1.02.03,1.41-.2.21-.46.31-.72.31Z"/>
                                                </g>
                                            </g>
                                        </svg>
                                        <div id='drewno-kolor'>
                                            <img 
                                                src={`${process.env.PUBLIC_URL}/images/drewno-rodzaje/${currentWoodType}.jpg`}
                                                alt={currentWoodType} 
                                            />
                                        </div>
                                        
                                        <svg id="arrow-right-thin" onClick={handleNextWood} data-name="Layer 2" width='20' height='20' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.54 24.11">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <g id="Path_58" data-name="Path 58">
                                                <path fill='#a9b1aa' d="M1,24.11c.25,0,.5-.09.69-.28l11.54-11.06c.2-.19.31-.45.31-.72s-.11-.53-.31-.72L1.69.28C1.29-.1.66-.09.28.31s-.37,1.03.03,1.41l10.78,10.33L.31,22.39c-.4.38-.41,1.02-.03,1.41.2.21.46.31.72.31Z"/>
                                                </g>
                                            </g>
                                        </svg>

                                    </div>
                                </div>


                                <div id='materialy-flex-metal'>
                                    <span id='color-value'>{metalColors[currentColorIndex]?.name || 'Loading...'}</span>
                                    <div className='arrows-container' id='metal'>
                                        <svg id="arrow-left-thin" onClick={handlePreviousColor} data-name="Layer 2" width='20' height='20' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.54 24.11">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <g id="Path_58" data-name="Path 58">
                                                <path fill='#a9b1aa' d="M12.54,24.11c-.25,0-.5-.09-.69-.28L.31,12.78c-.2-.19-.31-.45-.31-.72s.11-.53.31-.72L11.84.28c.4-.38,1.03-.37,1.41.03s.37,1.03-.03,1.41L2.45,12.06l10.78,10.33c.4.38.41,1.02.03,1.41-.2.21-.46.31-.72.31Z"/>
                                                </g>
                                            </g>
                                        </svg>
                                        <div id='metal-kolor' style={{ backgroundColor: metalColors[currentColorIndex]?.value || '#asdasd'}}></div>
                                        <svg id="arrow-right-thin" onClick={handleNextColor} data-name="Layer 2" width='20' height='20' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.54 24.11">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <g id="Path_58" data-name="Path 58">
                                                <path fill='#a9b1aa' d="M1,24.11c.25,0,.5-.09.69-.28l11.54-11.06c.2-.19.31-.45.31-.72s-.11-.53-.31-.72L1.69.28C1.29-.1.66-.09.28.31s-.37,1.03.03,1.41l10.78,10.33L.31,22.39c-.4.38-.41,1.02-.03,1.41.2.21.46.31.72.31Z"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                

                                
                            </div>
                            

                        </div>
                    

                        
                    </div>
                    
                </div>
                

                <div id="product-photo-container">
                    <img id="product-photo" src={`${process.env.PUBLIC_URL}${product.galleryPath}/img${currentPhotoIndex + 1}.jpg`} alt={product.name} />
                    <svg className={`arrow-left ${isTransparent ? 'transparent' : ''}`} xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 37 43">
                        <path  d="M18.041,5.952a4,4,0,0,1,6.917,0L39.508,30.99A4,4,0,0,1,36.049,37H6.951a4,4,0,0,1-3.459-6.01Z" transform="translate(0 43) rotate(-90)" fill="#a9b1aa"/>
                    </svg>

                    <svg className={`arrow-right ${isTransparent ? 'transparent' : ''}`} xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 37 43">
                        <path d="M18.041,5.952a4,4,0,0,1,6.917,0L39.508,30.99A4,4,0,0,1,36.049,37H6.951a4,4,0,0,1-3.459-6.01Z" transform="translate(37) rotate(90)" fill="#a9b1aa"/>
                    </svg>

                    <div id='prev-img-btn' onClick={previousImage} alt='previous photo'></div>
                    <div id='next-img-btn' onClick={nextImage} alt='next photo'></div>
                    
                    
                </div>
                
                {showContactModal && (
                    <ContactModal
                    closeContactModal={closeContactModal}
                    productName={product.name}
                    woodType={currentWoodType} // Stale, ale można zmienić, jeśli jest dynamiczne
                    productColor={metalColors[currentColorIndex]}
                    link={window.location.href}
                />)}
        </div>
    )
}; 

export default FurnitureItem;